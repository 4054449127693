import { Link } from 'gatsby';
import React from 'react';

const backgroundImage = (path) => ({ backgroundImage: `url(${path})` });

const Partial = ({}) => (
  <>
    <a id="maturidade"></a>
    <section className="section is-03">

      <div className="section-detail is-02">
        Transformação
      </div>

      <img src={require('../../images/backgrounds/bg-light.png')} alt="" aria-hidden="true" className="bg-section-light" />

      <div className="container ha-center">

        <h1 className="title is-primary">
          Nossa missão é fazer com que a sua empresa vá para o próximo nível de maturidade UX
        </h1>

        <div className="v-space"></div>

        <p className="text is-size-4">
        Mediante a um diagnóstico completo junto às suas diferentes áreas, produtos, processos e pessoas compartilharemos o seu nível de maturidade atual e maiores barreiras e oportunidades de evolução em relação à cultura UX. Em um processo personalizado e direcionado à sua cultura organizacional, indicaremos e co-criaremos com vocês os caminhos e práticas necessárias para o desenvolvimento desse novo estado onde todos passam a ser responsáveis pela experiência do cliente.
        </p>

        <div className="v-space-lg"></div>

        <a href="#contato" className="button is-primary is-rounded is-uppercase is-wide">
          <span className="is-uppercase">
            Fale com a gente
          </span>
        </a>

      </div>

    </section>
  </>
);

export default Partial;

